.landing-wrapper {
    position: relative;
    width: 100%;
    min-height: calc(100vh - var(--nav-top-height));
    background-color: rgba(var(--bg-200), 0.5);
    /* backdrop-filter: blur(1.5rem); */


}

.landing-header-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    /* margin-left: 15%;*/


}
.landing-header-main {
    /* text-shadow: 0px 0px 15px var(--fg-900); */
    /* color: var(--fg-500); */
    color: rgb(var(--text-900));

    font-family: 'teh', monospace;
    font-weight: 100;
    text-align: center;

    animation-name: landing-header-main-in;
    animation-duration: 750ms;
    animation-fill-mode: forwards;
    opacity: 0;
    animation-timing-function: ease-in-out;
    animation-delay: 750ms;

    /* filter: drop-shadow(0 2px 10px); */
    filter: drop-shadow(0 0 2px rgba(var(--text-900), 0.5));
    /* box shaddow in animation */

    /* font-size: 90px; */
    /* letter-spacing: 40px; */

    letter-spacing: clamp(0.5rem, 1vw, 4rem);

    /* font-size: clamp(3vw, 90px, 90px);;  */
    font-size: min(12vw, 90px);
}
.landing-header-tld {
    /* font-size: 42px; */
    font-family: Arial, Helvetica, sans-serif;
    /* letter-spacing: 25px; */
    letter-spacing: clamp(0.5rem, 2vw, 1.5rem);
    /* font-size: clamp(0.5rem, 5vw, 5rem);  */
    font-size: min(7.3vw, 55px);

}
.landing-header-seperator {
    width: 55%;
    border: 1px solid rgb(var(--text-800));
    margin: 30px auto 20px auto;
    /* margin: 50px 0 25px 0; */

    animation-name: landing-header-seperator-in;
    animation-duration: 750ms;
    animation-fill-mode: forwards;
    opacity: 0;
    animation-timing-function: ease-in-out;

}
.landing-header-sub {
    /* color: var(--fg-600); */
    color: rgb(var(--text-800));
    /* font-family: 'Roboto Mono', monospace; */
    font-family: Ubuntu Mono, monospace !important;
    font-size: 20px;
    font-weight: 100;
    text-align: center;

    animation-name: landing-header-sub-in;
    animation-duration: 750ms;
    animation-fill-mode: forwards;
    opacity: 0;
    animation-delay: 1500ms;
    animation-timing-function: ease-in-out;

}

@keyframes landing-header-main-in {
    0% { 
        transform: translateY(100%) scale(0.85);
        opacity: 0;
}
    35% {
        opacity: 0;
    }
    100% { 
        opacity: 1;        
    }
}
@keyframes landing-header-seperator-in {
    0% { 
        transform: scaleX(0%);
        opacity: 0;
    }

    100% { 
        opacity: 1;
        transform: scaleX(100%);
        
    }

}

@keyframes landing-header-sub-in {
    0% { 
        transform: translateY(-200%);
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% { 
        opacity: 1;
        
    }

}
