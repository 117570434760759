.dash-profile-container {
    max-width: 420px;
}
.dash-profile-container.center {
    margin: 0 auto;
}

.dash-profile-banner {
    width: 100%;
    padding-bottom: 30%;

    background-color: #404049;
    background-position: center;
    background-size: cover;
    position: relative;
}
.dash-profile-banner.round-top {
    border-radius: 10px 10px 0 0;
}
.dash-profile-avatar {
    width: min(120px, 20vw);
    height: min(120px, 20vw);

    background-position: center;
    background-size: cover;

    background-color: #404049;
    border-radius: 50%;

}
.dash-profile-username {
    display: block;
    font-size: 24px;
    color: rgb(var(--text-900));
    line-break: anywhere;
}
.dash-profile-uuid {
    display: block;
    font-size: 12px;
    color: rgb(var(--text-900));
    font-family: monospace;
    line-break: anywhere;
}
.dash-profile-basic-text {
}
.dash-profile-basic-wrapper {

    background-position: center;
    background-size: cover;
    /* background-size: 0; */
    margin: 0 auto;
    width: 100%;
    border-radius: 10px;

}
.dash-profile-basic-wrapper.flat-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.dash-profile-basic-inner {
    background-color: rgba(var(--bg-200), 0.5);
    backdrop-filter: blur(20px);


    /* nice */
    min-width: min(420px, 10vw);

    
    width: 100%;

    padding: 8px 0px;
    border-radius: 10px;
    border-radius: inherit;
    margin: 0 auto;
}


.dash-profile-basic-inner > .dash-profile-avatar, .dash-profile-basic-text {
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
}
.dash-profile-basic-bio-container {
    max-width: 420px;
}
.dash-profile-basic-bio {
    width: calc(100% - 20px);
    box-sizing: border-box;
    margin: 10px;
    padding: 10px;
    background-color: rgba(var(--bg-200), 0.5);
    min-height: 100px;
    resize: none;
    white-space: pre-line;

    color: rgb(var(--text-900));
    font-family: monospace;
}
.dash-profile-normal-basic-container {
    margin: 0 auto;

    /* display: block;
    position: relative;
    height: 100%;
    width: 100%; */

    /* position: absolute; */
    /* top: 50%; */ 
}

@media only screen and (max-width: 450px) {
    .dash-profile-basic-inner > .dash-profile-avatar, .dash-profile-basic-text {
        display: block;
        margin: 0 auto;
    }
    .dash-profile-basic-text {
        text-align: center;
    }
}