

  .dash-notification-wrapper {
    position: absolute;
    top: 0;
    width: 100%;

    z-index: 50;
    visibility: hidden;
  }
  .dash-notification-wrapper.hidden {
    visibility: none;
    display: none;
  }

  .dash-notification-container {
    visibility: visible;


    position: absolute;
    min-width: 600px;
    min-height: 100px;
    width: min(600px, 80vw);

    display: block;
    position: relative;
    margin: 0 auto;
  }
  .dash-notification {
    display: block;
    width: 100%;
    min-height: 50px;
    background-color: rgba(var(--bg-300), 1);
    backdrop-filter: 40px;
    border-radius: 10px;
    border: 1px solid rgba(var(--fg-900), 0.25);
    box-shadow: 5px 5px 15px 1px #000000;
    margin-top: 1vh;
    transition: all 250ms;
    animation: notification-in 250ms ease-in-out;
}
@keyframes notification-in {
    0% {
        opacity: 0;
        transform: translateY(-500%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}
.dash-notification-content {
    display: inline-block;
    padding: 5px;

}
.dash-notification-close {
    display: inline-block;
    padding: 10px 15px;
    color: rgb(var(--text-900));
    margin: 5px;
    border: 1px solid rgba(var(--fg-900), 0.25);
    border-radius: 10px;
    vertical-align: top;
}
.dash-notification-close:hover {
    background-color: rgba(var(--fg-900), 0.25);
}
.dash-notification-title {
    color: rgb(var(--text-900));
    font-size: 1.1rem;
    border-bottom: 1px solid rgba(var(--fg-900), 0.5);
    width: 100%;
}
.dash-notification-message {
    margin-top: 10px;
    color: rgb(var(--text-900));
}