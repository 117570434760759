:root {
    --nav-top-height: 40px;
    --nav-top-hover-distance: 5px;
    --nav-top-max-width: 800px;
    
}
.nav-top-wrapper {
    position: sticky;
    top: 0;
    width: 100%;
    min-height: var(--nav-top-height);
    background-color: rgb(var(--bg-200));
    box-sizing: border-box;
    border-bottom: 2px solid #3d3d3d;
    z-index: 15;
}
.nav-top-section {
    display: inline-block;
    min-height: var(--nav-top-height);
}

.nav-top-section.left {
    float: left;
}
.nav-top-section.right {
    float: right;
}
.nav-top-link {
    display: inline-block;
    box-sizing: border-box;
    height: var(--nav-top-height);
    border-bottom: solid 2px rgb(var(--fg-900));
    text-shadow: 0px 0px 15px rgb(var(--text-900));
    line-height: var(--nav-top-height);
    font-size: 14px;
    text-align: center;
    background-color: rgb(var(--bg-200));
    color: rgb(var(--text-900));
    text-decoration: none;

    margin-left: 5px;
    margin-right: 5px;
    padding: 0px 16px;

    transition: all 250ms;
}

.nav-top-link:hover {
    /* border-bottom: solid 2px var(--fg-900); */
    background-color: rgb(var(--bg-200));
    /* height: calc(var(--nav-top-height) + var(--nav-top-hover-distance) * 2); */
    /* line-height: calc(var(--nav-top-height) + var(--nav-top-hover-distance) * 2); */
    /* padding-top: calc(var(--nav-top-hover-distance) * 2); */
    transform: translateY(var(--nav-top-hover-distance));
}
.nav-top-inner {
    max-width: var(--nav-top-max-width);

    margin: 0 auto;
    padding: 0 1rem;
}
.nav-top-header {
    height: var(--nav-top-height);
    width: max-content;
    font-family: 'teh', monospace;
    font-size: 20px;
    line-height: var(--nav-top-height);
    color: rgb(var(--fg-900));
    text-shadow: 0px 0px 15px rgb(var(--fg-600));
    user-select: none;
    transition: all 250ms;
}
.nav-top-header:hover {
    color: rgb(var(--fg-800));
}


/* moblilele pesants */
@media only screen and (max-width: 500px) {
    .nav-top-wrapper {
        position: relative;
    }
    .nav-top-section {
        display: block;
    }
    .nav-top-section.left {
        float: none;

        /* no header */
        display: none;
    }
    .nav-top-section.right {
        float: none;
    }
    .nav-top-link {
        display: block;
        width: 100%;


        /* box-sizing: border-box; */
        height: auto;
        min-height: var(--nav-top-height);
        padding: 0.5rem;
        box-sizing: border-box;
        margin: 0;
    }
    
    .nav-top-inner {
        padding: 0 0;
    }
    .nav-top-header {

        /* no header */
        display: none;
        visibility: hidden;



        width: 100%;
        text-align: center;
    }
}
