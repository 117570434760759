

  .dash-window-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: rgba(var(--bg-100), 0.25);
    backdrop-filter: blur(5px);

    transition: all 250ms;
    z-index: 100;
  }
  .dash-window-wrapper.hidden {
    visibility: none;
    display: none;
  }

  .dash-window-container {
    position: absolute;
    min-width: 300px;
    min-height: 300px;
    width: min(300px, 80vw);
    padding-top: calc(50vh - 300px);

    display: block;
    position: relative;
    margin: 0 auto;
  }
  .dash-window {
    display: block;
    width: 100%;
    min-height: 100px;
    background-color: #333;
    border-radius: 10px;
    border: 1px solid rgba(var(--fg-900), 0.25);
    box-shadow: 5px 5px 15px 1px #000000;
    margin-bottom: 5vh;
    transition: all 250ms;
}
.dash-window-header {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    display: block;
    position: relative;

    width: 100%;
    padding: 10px 10px;
    box-sizing: border-box;
    
    color: #fff;
    background-color: #101015;
}
.dash-window-message {
    width: 100%;
    color: rgb(var(--text-900));
    padding: 10px 10px;
}
.dash-window-input-container {
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid rgba(var(--fg-900), 0.5);
    padding: 10px 15px;
    margin: 10px auto 0 auto;
}

.dash-window-input-button {
    padding: 5px 15px;
    border-radius: 5px;
    border: 1px solid rgb(var(--fg-900));
    background-color: rgb(var(--bg-800));
    color: rgb(var(--text-900));
}
.dash-window-input-button:hover {
    background-color: rgba(var(--fg-800), 0.2);
}
.dash-window-input-button.right {
    float: right;
}
.dash-window-input-button.important {
    background-color: rgba(var(--error), 0.2);
}
.dash-window-input-button.important:hover {
    background-color: rgba(var(--error), 0.8);
}
.dash-window:has( .dash-window-input-button.closes:active) {
    /* padding: 100px 0; */
    /* prolly brokey support ;-; */
    animation: window-close 250ms linear forwards;
}

@keyframes window-close {
    0% {
    
    }
    100% {
        transform: scaleY(10%);
        height: 0;
        margin: 0;
    }
}