:root {
    --dash-nav-size-closed: 55px;
    --dash-nav-size-opened: 200px;
    --dash-nav-side-item-height: var(--dash-nav-size-closed);
    --dash-nav-item-icon-size: 30px;
    --dash-default-animation-timing: 250ms;
    --dash-default-animation-timing-fast: 100ms;

    --dash-settings-option-height: 125px;
    --dash-settings-option-height: 95px;

    --dash-ui-opacity-default: 0.85;
    --dash-ui-opacity: 0.85;

    --dash-ui-blur-default: 100px;
    --dash-ui-blur: 100px;
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.hidden-text {
    /* blocked by default */
    color: #10101000; 
    background-color: rgb(var(--fg-500));
    
    transition: all var(--dash-default-animation-timing-fast);
}
.hidden-text:hover {
    /* c l a r i t y */
    color: inherit; 
    background-color: inherit;
    transform: scale(1);
}
.dash-wrapper {
    width: 100%;
    height: 100%;
}

.dash-content-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(100vw - var(--dash-nav-size-closed));
    /* background-color: #333; */
    transition: all var(--dash-default-animation-timing);
    overflow: auto;
}

.dash-nav-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: var(--dash-nav-size-closed);
    background-color: rgba(var(--bg-200), var(--dash-ui-opacity));
    transition: all var(--dash-default-animation-timing);
    border-right: 1px solid #333;
    backdrop-filter: blur(var(--dash-ui-blur));
}

.dash-nav-container:hover {
    width: var(--dash-nav-size-opened);
}

.dash-nav-container:hover~.dash-content-container {
    width: calc(100vw - var(--dash-nav-size-opened));
}

.dash-nav-container:hover .dash-nav-item-label {
    transform: translateX(0%);
    opacity: 1;
}


.dash-nav-item {
    width: 100%;
    box-sizing: border-box;
    /* padding: 8px; */
    padding-left: calc((var(--dash-nav-size-closed) - var(--dash-nav-side-item-height))/2);

    height: var(--dash-nav-side-item-height);

    overflow: hidden;
    display: block;
    transition: all var(--dash-default-animation-timing-fast);
}
.dash-nav-item.active {
    background-color: rgba(var(--bg-100), 0.3);
}
.dash-nav-item:hover {
    background-color: rgba(var(--bg-100), 0.3);
    border-left: 3px solid rgb(var(--fg-900));
}

.dash-nav-item * {
    display: inline-block;
}

.dash-nav-container:hover .dash-nav-item-icon.spinny {
    /* funny spinny */
    transform: rotate(180deg);
}

.dash-nav-item-icon {
    background-size: var(--dash-nav-item-icon-size);
    background-position: center;
    background-repeat: no-repeat;
    /* padding: calc((var(--dash-nav-side-item-height) - var(--dash-nav-item-icon-size)) / 2); */
    height: var(--dash-nav-item-icon-size);
    width: var(--dash-nav-item-icon-size);
    margin: calc((var(--dash-nav-side-item-height) - var(--dash-nav-item-icon-size)) / 2);
    box-sizing: border-box;
    filter: invert(0.75);
    transition: all var(--dash-default-animation-timing-fast);
}
.dash-nav-item-icon.no-invert {
    filter: invert(0); 
}
.dash-nav-item-icon.rounded {
    border-radius: 50%;
}

.dash-nav-item-label {
    font-size: 16px;
    color: rgb(var(--text-900));
    /* visibility: hidden; */
    overflow: hidden;
    vertical-align: top;

    height: var(--dash-nav-side-item-height);
    line-height: calc(var(--dash-nav-side-item-height));
    text-align: center;

    transition: all var(--dash-default-animation-timing-fast);
    opacity: 0;
    transform: translateX(calc(0px - var(--dash-nav-size-closed)));
}

.dash-nav-seperator {
    width: 80%;
    border-top: 1px solid rgb(var(--fg-900));
    margin: 10px auto;

}



/* flexcol section alignments */
.dash-nav-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: normal;
    align-content: normal;

    overflow: auto;
}
.dash-nav-section.top {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
}
.dash-nav-section.bottom {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
}
/* smaller scroll on nav */
.dash-nav-container::-webkit-scrollbar {
    width: 2px;
}

.dash-page {
    /* min-height: 94vh; */
    background-color: rgba(var(--bg-200), var(--dash-ui-opacity));
    /* background: linear-gradient(180deg, rgba(var(--bg-200), var(--dash-ui-opacity)) 0%, rgba(var(--bg-200), var(--dash-ui-opacity)) 50%,rgba(var(--bg-200), calc( var(--dash-ui-opacity) / 1.25)) 75%, rgba(0,0,0,0) 90%); */
    backdrop-filter: blur(var(--dash-ui-blur));
    padding: 10px 10px 35px 10px;
}
.dash-page.narrow {
    max-width: 1500px;
    margin: 2vh auto;
}
.dash-page.narrower {
    max-width: 800px;
    margin: 2vh auto;
}


.dash-header-line {
    overflow: hidden;
    text-align: center;
    font-size: 2rem;
    font-weight: 400;
    padding: 1rem;

    color: rgb(var(--text-900));
}
.dash-header-line:before,
.dash-header-line:after {
    border-color: var(--fg-800);
    content: "";
    display: inline-block;
    /* height: 1px; */

    position: relative;
    vertical-align: middle;
    width: 50%;


    overflow: hidden;
    border-style: inset;
    border-width: 1px;
    border-color: var(--fg-800);
}
.dash-header-line:before {
    right: 0.5em;
    margin-left: -50%;
}
.dash-header-line:after {
    left: 0.5em;
    margin-right: -50%;
}



.dash-settings-page {
    display: grid; 
    grid-template-columns: 10% 90%; 
    grid-template-rows: 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      "tab content"; 

  }
  .dash-settings-nav-tab-list {
    position: relative;
    display: inline-block;
    grid-area: tab; 
}
.dash-settings-nav-tab {
    padding: 15px;
    margin: 0px auto;
    /* background-color: #333; */
    color: #fff;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* width: 80%; */
    list-style-type: none;
    transition: all var(--dash-default-animation-timing-fast);
    border-right: 1px solid rgb(var(--fg-900));
    width: 100%;
    box-sizing: border-box;
    display: block;
    text-decoration: none;
}
.dash-settings-nav-tab:hover {
    border-right: 5px solid rgb(var(--fg-900));
}
.dash-settings-nav-tab.active:hover {
    border-right: 7px solid rgb(var(--fg-800));
}
.dash-settings-nav-tab.active {
    /* background-color: #555; */
    border-right: 3px solid rgb(var(--fg-800));
}
.dash-settings-tab-panel {
    display: none;
    vertical-align: top;
    width: 100%;
    /* background-color: #333; */
    min-height: 10vh;
    float: right;
    grid-area: content;

    
    animation-name: react-tab-panel-in;
    animation-duration: 100ms;

    display: inline-block;
    
}

@keyframes react-tab-panel-in {
    0% {
        transform: scale(0.9);
        /* transform: scale(0.4); */
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}


.dash-settings-option {
    width: 80%;
    min-height: var(--dash-settings-option-height);
    max-height: max-content;
    margin: 10px auto;
    background-color: rgba(var(--bg-300), var(--dash-ui-opacity));
    border-radius: 15px;
    position: relative;
}
.dash-settings-option * {
    display: inline;
}
.dash-settings-option-left {
    float: left;
    min-height: var(--dash-settings-option-height);
    width: 70%;
    box-sizing: border-box;
    padding: 15px 20px;
}
.dash-settings-option-right {
    float: right;
    height: var(--dash-settings-option-height);
    width: 30%;
    position: relative;
    /* background-color: red; */
}
.dash-settings-option-left.large-input {
    width: 29%;
}
.dash-settings-option-right.large-input {
    width: 70%;
}
.dash-settings-option-title {
    font-size: 24px;
    color: rgb(var(--text-900));
    margin-bottom: 8px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}
.dash-settings-option-description {
    font-size: 14px;
    color: rgb(var(--text-800));
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

.dash-settings-option-input-label {
    color: rgb(var(--text-900));
    text-align: center;
    width: 100%;
}
.dash-settings-option-input-container {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);

}
.dash-settings-option-underneath-wrapper {
    width: 100%;
    display: inline-block;
    /* margin: 0 auto; */
    color: rgb(var(--text-800));
    padding-bottom: 20px;
    position: relative;
    height: max-content;
}

.dash-settings-option-underneath {
    width: 90%;
    border-top: 1px solid rgb(var(--fg-600));
    padding-top: 15px;
    box-sizing: border-box;
    display: block;
    margin: 0 auto;
}




.dash-settings-option-underneath-list-item {
    width: 90%;
    display: block;
    padding: 0 0 0 15px;
    border-left: 2px solid rgb(var(--fg-600));
    margin: 25px auto;
    border-radius: 3px;
    animation: fade-in 500ms ease-in-out forwards;
}


.dash-settings-option-input-container * {
    display: block;
    width: 60%;
    margin: 0 auto;
}
.dash-settings-option-input-text {
    width: 90%;
    box-sizing: border-box;
    background-color: rgb(var(--bg-600));
    border: solid 1px rgb(var(--fg-800));
    padding: 0.5rem;
    font-family: monospace;
    color: rgb(var(--text-900));
    border-radius: 5px;
}
.dash-settings-option-input-number {
    box-sizing: border-box;
    background-color: rgb(var(--bg-600));
    border: solid 1px rgb(var(--fg-800));
    padding: 10px;
    font-family: monospace;
    color: rgb(var(--text-900));
    border-radius: 5px;
}
.dash-settings-option-input-drop {
    box-sizing: border-box;
    background-color: rgba(var(--bg-200), var(--dash-ui-opacity));
    border: solid 1px rgb(var(--fg-800));
    padding: 10px;
    font-family: monospace;
    color: rgb(var(--text-900));
    border-radius: 5px;   
    width: max-content;
}

.dash-settings-option-input-button {
    width: max-content;
    padding: 10px 15px;
    background-color: rgb(var(--bg-600));
    border: solid 1px rgb(var(--fg-800));
    color: rgb(var(--text-900));
    border-radius: 5px;
}
.dash-settings-option-input-button:hover {
    background-color: rgb(var(--bg-400));
}
.dash-settings-option-input-button:active {
    background-color: rgb(var(--bg-600));
}


.switch {
    position: relative;
    /* display: block; */
    width: 84px;
    height: 24px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: rgb(var(--fg-800));
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px rgb(var(--fg-800));
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }


  .dash-settings-header {
    font-size: 32px;
    /* text-align: center; */
    width: 80%;
    margin: 0 auto 20px auto;
    padding: 20px 0px;
    color: rgb(var(--text-900));
    font-family: Arial, Helvetica, sans-serif;
    border-bottom: 1px solid rgb(var(--fg-900));

  }

  @media only screen and (max-width: 1100px) {
    .dash-settings-page {
        grid-template-columns: 15% 85%; 
    }

    .dash-settings-option {
        width: 90%;
    }
    .dash-settings-header {
        width: 90%;
    }
}

@media only screen and (max-width: 900px) {
    .dash-settings-page {
        grid-template-columns: 20% 80%; 
    }

    .dash-settings-option {
        width: 95%;
        min-height: auto;
        max-height: none;
        height: auto;
    }
    .dash-settings-header {
        width: 95%;
    }

    .dash-settings-option * {
        display: block;
    }
    .dash-settings-option-left {
        float: none;
        min-height: auto;
        width: 100%;
    }
    .dash-settings-option-right {
        float: none;
        height: auto;
        width: 100%;
        min-height: 4rem;
    }
    .dash-settings-option-underneath {
        width: 90%;
    }
    .dash-settings-option-left.large-input {
        width: 100%;
    }
    .dash-settings-option-right.large-input {
        width: 100%;
    }
}

@media only screen and (max-width: 700px) {
    .dash-settings-page {
        grid-template-columns: 20% 80%; 
    }

    .dash-settings-option {
        width: 100%;
    }
    .dash-settings-header {
        width: 100%;
    }

    .dash-settings-page {
        display: block; 
        grid-template-columns: 10% 90%; 
        grid-template-rows: 1fr; 
        gap: 0px 0px; 
        grid-template-areas: 
          "tab content"; 
    
      }
      .dash-settings-nav-tab-list {
        position: relative;
        display: block;
        /* width: max-content; */
        margin: 0 auto;
    }
    .dash-settings-tab-panel {
        display: block;
        width: 90%;
        margin: 0 auto;
        float: none;
        animation-name: react-tab-panel-in;
        animation-duration: 100ms;
    
    }


    .dash-settings-nav-tab {
        /* padding: 15px; */
        margin: 0px auto;
        /* border-bottom: 1px solid rgb(var(--fg-900)); */
        width: auto;
        display: block;
        width: 100%;
        /* border-right: 0; */
    }
    /* .dash-settings-nav-tab:hover {
        border-bottom: 5px solid rgb(var(--fg-900));
    }
    .dash-settings-nav-tab--selected:hover {
        border-bottom: 5px solid rgb(var(--fg-800));
    }
    .dash-settings-nav-tab--selected {
        border-bottom: 1px solid rgb(var(--fg-800));
    } */
}



.dash-placeholder-loading-wrapper {
    display: block;
    width: 100%;
    min-height: 10vh;
    position: relative;
    animation: fade-in 500ms ease-in-out forwards;
}

.dash-placeholder-loading-inner {
    width: max-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.dash-loading {
    width: 3rem;
    height: 3rem;

    border-radius: 50%;
    border-top: solid 1px rgb(var(--fg-600));

    animation: dash-loading 500ms linear infinite;
    display: block;


}
  
@keyframes dash-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

  
