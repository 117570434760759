.errorpage-content-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #101015ee;
    box-sizing: border-box;
    padding: 10%;
}
.errorpage-header {
    font-size: 32px;
    color: #fff;
}
.errorpage-description {
    font-size: 16px;
    color: #fff;
}
.errorpage-image {
    /* background-image: url(./../../../public/assets/img/EternalPain.png); */
    background-position: center;
    background-size: contain;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 500px;
    height: 500px;
    z-index: -1;
}