
/* Default themes incase theme is unloaded */
:root {



    /* --fg-accent : #1FB2A6;
    --fg-success: #36D399;
    --fg-info   : #3ABFF8;
    --fg-warning: #FBBD23;
    --fg-error  : #F87272; */

    /* 2 color gradient
    warning moved to yellow
    accent is attached to bottom of gradient - darker/deeper */
    /* --accent : #324bcc;
    --success: #5290ee;
    --info   : #ac7fdc;
    --warning: #e7d17a;
    --error  : #f46c86;
    
    --bg-500: #261c35;
    --bg-400: #1d192b;
    --bg-300: #161520;
    --bg-200: #101015;
    --bg-100: #000000;
 */
    /* --fg-900: #f26c4f;
    --fg-800: #f972a9;
    --fg-700: #c797ee;
    --fg-600: #76b9ff;
    --fg-500: #52cfee; */
/* 
    --fg-900: #52cfee;
    --fg-800: #4d38ed;

    --fg-700: #c797ee;
    --fg-600: #76b9ff;
    --fg-500: #52cfee;

    --text-900: #ffffff;
    --text-800: #b9b9b9;
    --text-700: #787878;
    --text-600: #3d3d3d;
    --text-500: #030303; */



 --accent : 50, 75, 204;
 --success: 82, 144, 238;
 --info   : 172, 127, 220;
 --warning: 231, 209, 122;
 --error  : 244, 108, 134;
 
 --bg-500: 38, 28, 53;
 --bg-400: 29, 25, 43;
 --bg-300: 22, 21, 32;
 --bg-200: 16, 16, 21;
 --bg-100: 0, 0, 0;

 --fg-900: 82, 207, 238;
 --fg-800: 77, 56, 237;

 --fg-700: 199, 151, 238;
 --fg-600: 118, 185, 255;
 --fg-500: 82, 207, 238;

 --text-900: 255, 255, 255;
 --text-800: 185, 185, 185;
 --text-700: 120, 120, 120;
 --text-600: 61, 61, 61;
 --text-500: 3, 3, 3;



    
    --background-wallpaper-url-default: url(/public/assets/img/wallpapers/Rooftop\ Chillin.jpg);
    
    --background-wallpaper-url: url(/public/assets/img/wallpapers/Rooftop\ Chillin.jpg);

    --background-wallpaper-opacity-default: 100;
    --background-wallpaper-opacity: 100;

    --background-wallpaper-blur-default: 10px;
    --background-wallpaper-blur: 10px;
}

@import 'theme.css';

.app {
    width: 100vw;
    height: 100vh;
    position: absolute;

    font-family: monospace;

}

.background-wrapper {
    width: 100%;
    height: 100%;
    z-index: -1;

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-color: rgb(16, 16, 21);

    /* background-color: #333; */

    /* background-color: var(--bg-100); */
}
.background-wrapper * {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.background-shade {
    /* background-color: #324bcc55; */
    backdrop-filter: blur( calc( var(--background-wallpaper-blur) ) );

    /* background-image: linear-gradient(rgba(10, 10, 10, 0.3), rgba(0, 0, 0, 0.6)), repeating-linear-gradient(0, transparent, transparent 2px, black 3px, black 3px); */
    /* background-image: linear-gradient(rgba(var(--bg-100), 0.3), rgba(var(--bg-100), 0.6)), repeating-linear-gradient(0, transparent, transparent 2px, rgb(var(--bg-100)) 3px, rgb(var(--bg-100)) 3px); */
    background-image: linear-gradient(rgba(var(--bg-100), 0.3), rgba(var(--bg-100), 0.6)), repeating-linear-gradient(0, transparent, transparent 3px, rgb(var(--bg-100)) 4px, rgb(var(--bg-100)) 4px);
}
.background-img {
    /* opacity: 0.1; */
    /* background-image: url(https://www.pixelstalk.net/wp-content/uploads/2016/10/Anime-Landscape-Wallpaper-HD.jpg); */
    /* background-image: linear-gradient(rgba(10, 10, 10, 0.3), rgba(0, 0, 0, 0.6)), repeating-linear-gradient(0, transparent, transparent 2px, black 3px, black 3px), url(https://wallpaper.dog/large/20519137.jpg); */
    /* background-image: linear-gradient(rgba(10, 10, 10, 0.3), rgba(0, 0, 0, 0.6)), repeating-linear-gradient(0, transparent, transparent 2px, black 3px, black 3px), url(./../../../public/assets/img/20519137.jpg); */
    background-image: var(--background-wallpaper-url);
    /* background-image: url(https://free4kwallpapers.com/uploads/originals/2022/03/28/anime-landscape-for-desktop-scenery-clouds-stars-buildings-wallpaper.jpg); */
    /* background-image: url(https://wallpaperaccess.com/full/219171.jpg); */
    /* background-image: url(https://images.squarespace-cdn.com/content/v1/5dfe9a75a217b83c46347f46/1647502180862-ZT0AXG7RN5AQX5GVTNMZ/8.jpg?format=2500w); */
    /* background-image: url(https://c0.wallpaperflare.com/path/614/523/680/forest-tree-fog-mist-c5f4869747c74c4eb05d9dc23b4ea546.jpg); */
    
    background-position: center;
    background-size: cover;
    transform: scaleX(-100%);
    opacity: calc( var(--background-wallpaper-opacity) / 100);
}
.background-video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /* visibility: hidden; */
}

.alert-wrapper {
    z-index: 900;
    position: fixed;
    top: 0;

    width: 100vw;
    margin: 0 auto;
}
.alertbox {
    max-width: max-content;
    min-width: 600px;
    margin: 20px auto;
    border-radius: 5px;
    padding: 20px;
    font-size: 14px;
    font-weight: 500;
    background-color: var(--info);

    transition: all var(--alert-animation-duration);
    animation: alertbox-animation-duration 2000ms linear forwards;

}


.alertbox:hover {
    transform: translateX(20px);
}

.alertbox.success {
    background-color: var(--success);
}
.alertbox.info {
    background-color: var(--info);
}
.alertbox.warning {
    background-color: var(--warning);
}

.alertbox.error {
    background-color: var(--error);
}

