/* reset default browser styling */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, caption {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}
body {
    background-color: #010105;
    color: #f00
}
@import url("https://fonts.googleapis.com/css?family=Inconsolata|Roboto+Mono|Ubuntu+Mono|Cutive+Mono");

@font-face {
    font-family: teh;
    src: url(/public/assets/fonts/teh.ttf);
}
/* custom scrollbar :/ */
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: rgba(var(--bg-300), 0.5); 
}
::-webkit-scrollbar-thumb {
    background: rgb(var(--fg-500), 0.5);
    border-radius: 2px;
}
::-webkit-scrollbar-thumb:hover {
    background: rgb(var(--fg-600), 0.5);
}
@media only screen and (max-width: 700px) {
    ::-webkit-scrollbar {
        width: 8px;
    }
}
@media only screen and (max-width: 500px) {
    ::-webkit-scrollbar {
        width: 4px;
    }
}
.site-logo-text {
    font-family: Ubuntu Mono, monospace !important;
    /* letter-spacing: 3px; */
}
.ff-mono {
    font-family: monospace;
}