/* undefined theme */

:root {

    --accent : 50, 75, 204;
    --success: 82, 144, 238;
    --info   : 172, 127, 220;
    --warning: 231, 209, 122;
    --error  : 244, 108, 134;
    
    --bg-500: 38, 28, 53;
    --bg-400: 29, 25, 43;
    --bg-300: 22, 21, 32;
    --bg-200: 16, 16, 21;
    --bg-100: 0, 0, 0;

    --fg-900: 82, 207, 238;
    --fg-800: 77, 56, 237;

    --fg-700: 199, 151, 238;
    --fg-600: 118, 185, 255;
    --fg-500: 82, 207, 238;

    --text-900: 255, 255, 255;
    --text-800: 185, 185, 185;
    --text-700: 120, 120, 120;
    --text-600: 61, 61, 61;
    --text-500: 3, 3, 3;

}


/* specific themes */
.theme-dark {

    --accent : 50, 75, 204;
    --success: 82, 144, 238;
    --info   : 172, 127, 220;
    --warning: 231, 209, 122;
    --error  : 244, 108, 134;
    
    --bg-500: 34, 35, 36;
    --bg-400: 30, 30, 32;
    --bg-300: 25, 25, 28;
    --bg-200: 21, 21, 25;
    --bg-100: 16, 16, 21;

    
    --fg-900: 82, 207, 238;
    --fg-800: 77, 56, 237;

    --fg-700: 199, 151, 238;
    --fg-600: 118, 185, 255;
    --fg-500: 82, 207, 238;

    --text-900: 255, 255, 255;
    --text-800: 185, 185, 185;
    --text-700: 120, 120, 120;
    --text-600: 61, 61, 61;
    --text-500: 3, 3, 3;

}

.theme-mono {

    --accent : 50, 75, 204;
    --success: 82, 144, 238;
    --info   : 172, 127, 220;
    --warning: 231, 209, 122;
    --error  : 244, 108, 134;
    
    --bg-500: 16, 16, 21;
    --bg-400: 16, 16, 21;
    --bg-300: 16, 16, 21;
    --bg-200: 16, 16, 21;
    --bg-100: 16, 16, 21;

    --fg-900: 0, 255, 216;
    --fg-800: 0, 255, 188;
    --fg-700: 0, 255, 152;
    --fg-600: 0, 255, 105;
    --fg-500: 0, 255, 30;

    --text-900: 221, 241, 246;
    --text-800: 161, 175, 179;
    --text-700: 105, 114, 116;
    --text-600: 54, 58, 59;
    --text-500: 3, 3, 3;    
 
}


.theme-nord {
    
    --accent : 129, 161, 193;
    --success: 136, 192, 208;
    --info   : 163, 190, 140;
    --warning: 235, 203, 139;
    --error  : 235, 79, 52;
    
    --bg-500: 80, 92, 104;
    --bg-400: 71, 82, 94;
    --bg-300: 63, 71, 84;
    --bg-200: 54, 62, 74;
    --bg-100: 46, 52, 64;

    --fg-900: 148, 185, 221;
    --fg-800: 76, 137, 212;
    --fg-700: 199, 151, 238;
    --fg-600: 118, 185, 255;
    --fg-500: 82, 207, 238;

    --text-900: 203, 220, 254;
    --text-800: 160, 174, 203;
    --text-700: 120, 131, 153;
    --text-600: 81, 90, 107;
    --text-500: 46, 52, 64;

    
    
/*     
    
    
    #2E3440
    #3B4252
    #434C5E
    #4C566A
    
    #2e3440
    #363e4a
    #3f4754
    #47525e
    #505c68
    #596672
    #63717c
    #6c7c87
    #768791
    #80929c */

}

.theme-opera {

    --accent : 211, 181, 255;
    --success: 4, 194, 156;
    --info   : 255, 0, 179;
    --warning: 255, 0, 72;
    --error  : 235, 79, 52;

    --bg-500: 57, 48, 112;
    --bg-400: 51, 35, 82;
    --bg-300: 36, 31, 71;
    --bg-200: 22, 18, 43;
    --bg-100: 14, 12, 29;
    

    --fg-900: 194, 153, 255;
    --fg-800: 183, 135, 255;
    --fg-700: 169, 112, 255;
    --fg-600: 118, 185, 255;
    --fg-500: 82, 207, 238;

    --text-900: 211, 181, 255;
    --text-800: 152, 156, 218;
    --text-700: 103, 129, 176;
    --text-600: 66, 101, 131;
    --text-500: 42, 72, 88;
    
}



.theme-light {

    --accent : 50, 75, 204;
    --success: 82, 144, 238;
    --info   : 172, 127, 220;
    --warning: 231, 209, 122;
    --error  : 244, 108, 134;
    
    --bg-500: 250, 248, 252;
    --bg-400: 205, 205, 212;
    --bg-300: 160, 164, 173;
    --bg-200: 115, 125, 135;
    --bg-100: 72, 89, 98;

    --fg-900: 82, 207, 238;
    --fg-800: 77, 56, 237;

    --fg-700: 199, 151, 238;
    --fg-600: 118, 185, 255;
    --fg-500: 82, 207, 238;

    --text-900: 3, 3, 3;
    --text-800: 22, 22, 22;
    --text-700: 34, 34, 34;
    --text-600: 47, 47, 47;
    --text-500: 61, 61, 61;
    
}



