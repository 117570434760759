.dash-admin-table {
    width: max-content;
    margin: 0 auto;
    color: rgb(var(--text-900));
}
.dash-admin-table-header {
    width: 100%;
}
.dash-admin-table-header * {
    text-align: left;
    padding: 15px 0;
    position: sticky;
    top: 0;
    background-color: rgba(var(--bg-500), 1);
    
}
.dash-admin-table-body * {
    padding: 10px 0;
    margin: 20px 0;
    border-bottom: 1px solid #fff;
}
.dash-admin-table-body * * {
    padding: 10px 20px;
}
.dash-admin-table-body *:hover {

    background-color: rgba(var(--fg-900), 0.2);
}
.dash-admin-button {
    padding: 10px 15px;
    border-radius: 10px;
    border: 0;
    background-color: rgba(var(--bg-400), 0.5);
    color: rgb(var(--text-900));
    border: 1px solid rgb(var(--warning));
}