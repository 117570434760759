
.auth-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    min-width: 30%;
    /* padding: 0 5rem; */
    max-width: 500px;
    /* right: 0; */
    background-color: #101015aa;
    backdrop-filter: blur(1rem);
}


.auth-container {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    min-height: 500px;
    width: 100%;
}
@media only screen and (max-height: 500px) {
    .auth-container {

        position: relative;
        top: 10%;
        left: 0%;
        transform: translateY(0%) translateX(0%);
        min-height: 500px;
        width: 100%;
    }
    .auth-wrapper {
        bottom: auto;
    }
}
.auth-header {
    position: relative;
    width: 100%;
    text-align: center;
    display: block;
    font-size: 64px;
    font-family: 'teh', arial, monospace;
    color: rgb(var(--fg-900));
    text-decoration: none;
    padding-top: 10%;
    /* position: relative; */
    /* padding: 0 10%; */

    /* background-image: url(https://encrypting.host/VFWdLsYPtV.png?key=IzSYdgjJYZsciz); */
    /* background-image: url(https://i.uwu.bz/dYGCRBtPTf.png?key=02WZfmsW2ORRnw); */
    /* background-size: contain; */
    /* background-position: center; */
    /* background-repeat: no-repeat; */
    /* min-height: 1.2em; */
    transition: all 100ms;
}

.auth-header:hover {
    color: rgb(var(--fg-800));
}
@media only screen and (max-width: 1400px) {
    .auth-header {
        font-size: 50px;
    }
}
@media only screen and (max-width: 320px) {
    .auth-header {
        font-size: 13vw;
    }
}
.auth-form-container {
    position: relative;

    width: 80%;
    margin: 5% 10%;
    /* background-color: #101015aa; */
    min-height: max-content;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
}



.auth-input {
    position: relative;

    display: block;
    width: 80%;
    background-color: #101015aa;
    border: 1px solid #ffffff88;
    padding: 8px;
    margin: 16px auto;
    box-sizing: border-box;
    color: #ddd;

}
@media only screen and (max-width: 1150px) {
    .auth-wrapper {

        min-width: 100%;
        padding: 0 0;
    }
    .auth-form-container {

        width: 50%;
        margin: 5% 25%;

    }
}
@media only screen and (max-width: 800px) {

    .auth-form-container {

        width: 80%;
        margin: 5% 10%;

    }
}

@media only screen and (max-width: 450px) {
    .auth-input {
        width: 90%;
    }
    .auth-form-container {

        width: 100%;
        margin: 5% 0%;

    }
}
.auth-text-link {
    
    color: #fff;
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
}
.auth-form-error {
    text-align: center;
    width: 100%;
}